/*
 * @Author: shanshan 1752747088@qq.com
 * @Date: 2023-03-10 14:23:58
 * @LastEditors: shanshan 1752747088@qq.com
 * @LastEditTime: 2023-08-29 17:12:53
 * @FilePath: \dxzx-h5\src\api\pages.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */


import request from './axios'
// 根据二维码id查询渠道状态
export function getchannelstatusByQrId (obj) {
    return request({
      url: '/south/southOwnerLoan/getChannelStatusByQrId',
      method: 'post',
      data: obj
    })
}
export function enterTable (obj) {
  return request({
    url: '/south/southOperation/enterTable',
    method: 'get',
    params: obj
  })
}
export function saveStatisticsTime (obj) {
  return request({
    url: '/south/southOperation/saveStatisticsTime',
    method: 'get',
    params: obj
  })
}
// 上传图片
export function uploadPic (obj) {
    return request({
      url: '/south/southUser/xcx/upload',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: obj
    })
}
// 上传资料
export function uploadDocuments (obj) {
    return request({
      url: '/south/southOwnerLoan/uploadDocuments',
      method: 'post',
      data: obj
    })
}

// 
export function auditStatus (obj) {
  return request({
    url: '/south/southOwnerLoan/auditStatus',
    method: 'get',
    params: obj
  })
}
export function whetherScreen (obj) {
  return request({
    url: '/south/southMinAppIcon/getIconStatus',
    method: 'get',
    params: obj
  })
}
export function southOwnerLoanAdd (obj) {
  return request({
    url: '/south/southOwnerLoan/add',
    method: 'post',
    data: obj
  })
}
export function applyProduct (obj) {
  return request({
    url: '/south/southOwnerLoan/applyProduct',
    method: 'post',
    data: obj
  })
}
//发送验证码
export function sendSMS (phone) {
  return request({
    url: '/south/southUser/xcx/sendSMS/'+phone,
    method: 'post',
  })
}

export function checkCode (phone,code) {
  return request({
    url: '/south/southOwnerLoan/checkCode/'+phone+'/'+code,
    method: 'get',
  })
}
export function getLoanProductList (obj) {
  return request({
    url: '/south/southOwnerLoan/getLoanProductList',
    method: 'get',
    params: obj
  })
}