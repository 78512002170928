/*
 * @Author: shanshan 1752747088@qq.com
 * @Date: 2023-03-10 14:23:58
 * @LastEditors: shanshan 1752747088@qq.com
 * @LastEditTime: 2023-03-10 14:55:34
 * @FilePath: \dxzx-h5\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

import '@/styles/commom.scss';

import point from '@/api/point.js'
app.config.globalProperties.$point=point;

//全局js
import './utils/rem.js' 
// 1. 引入你需要的组件
import { Button } from 'vant';
import { Uploader } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Field, CellGroup } from 'vant';
import { Icon } from 'vant'
import { Toast } from 'vant';
import { Popup } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Dialog } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

app.use(store).use(router).use(Radio).use(RadioGroup).use(Popup).use(Button).use(Swipe).use(SwipeItem).use(Uploader).use(Field).use(CellGroup).use(Icon).use(Toast).use(Dialog).mount('#app')
