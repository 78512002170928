<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>
<script>
import { showToast } from "vant";
export default {
  name: "HomeView",
  props: {
    pageTitle: String,
    backgroundColor: String,
  },
  data() {
    return {
      qrId: "", //渠道id
    };
  },
  mounted() {
    // 通过url参数获取渠道id
    // this.qrId = window.location.href.split("?")[1].split("id=")[1];
    let url = window.location.href
    var object = {};
    if (url.indexOf("?") != -1) {
      //url中存在问号，也就说有参数。
      var str = url.split("?")[1]; //得到?后面的字符串
      var strs = str.split("&"); //将得到的参数分隔成数组
      for (var i = 0; i < strs.length; i++) {
        let index = strs[i].indexOf("=") +1;
        object[strs[i].split("=")[0]] = strs[i].substring(index); //得到{access_token:'123456',areaId:'www'}
      }
    }
    localStorage.setItem("qrId", object.id);
    localStorage.setItem("token", object.token);
    console.log(object)
    console.log(object.id)
  },
}
</script>
<style>
html,body,#app{
  height: 100%;
  background-color: #F7F7F7;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 7.5rem;
  height: 100%;
  margin: 0 auto;
  
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
