/*
 * @Author: shanshan 1752747088@qq.com
 * @Date: 2023-03-10 14:23:58
 * @LastEditors: shanshan 1752747088@qq.com
 * @LastEditTime: 2023-08-29 19:11:06
 * @FilePath: \dxzx-h5\src\api\axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import axios from 'axios'

// ！！！！！！！！！！！！！！！！！！！！！！！！！！！
// ！！！注意打包时需要注意vue.config的publicPath
// ！！！注意打包时需要注意vue.config的publicPath
// ！！！注意打包时需要注意vue.config的publicPath
// ！！！！！！！！！！！！！！！！！！！！！！！！！！！

// axios.defaults.baseURL = 'jeecg-boot/'
// axios.defaults.baseURL = 'https://39q42x5106.oicp.vip/jeecg-boot/'//55
// axios.defaults.baseURL = 'https://xxjjdx.xaxn-tech.com/jeecg-boot/'//大向55
axios.defaults.baseURL = 'https://dxsx.xaxn-tech.com/jeecg-boot/'//大向45
// axios.defaults.baseURL='https://nxjdfwtx.nxjtxy.cn/nya/test/jeecg-boot/'//宁夏测试
// axios.defaults.baseURL='https://nxjdfwtx.nxjtxy.cn/nya/jeecg-boot/'//宁夏线上
axios.defaults.timeout = 30000
axios.defaults.headers['Content-Type'] = "application/json; charset=UTF-8"
axios.interceptors.request.use(config => {
  config.headers['X-Access-Token'] = localStorage.getItem("token") 
  // config.headers['X-Access-Token'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2Nzg5MzA4MDIsInVzZXJuYW1lIjoiMTMwODk5OTAwMDQifQ.U3WkuxXk6t3wiPlZssCAOzoTWb9n66Yn01H8WzqYH-Y"
  config.headers['X-Requested-With'] = "XMLHttpRequest"

  console.log(config)
  return config
}, error => {
  return Promise.reject(error)
})
axios.interceptors.response.use(response => {
    console.log(response.data)
    return response;
}, error => {
    return Promise.reject(new Error(error))
})

export default axios
