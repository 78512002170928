<!--
 * @Author: shanshan 1752747088@qq.com
 * @Date: 2023-08-24 15:03:02
 * @LastEditors: shanshan 1752747088@qq.com
 * @LastEditTime: 2023-09-18 17:59:37
 * @FilePath: \dxzx-form-h5\src\views\HomeView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="home">
    <!--  status==1,已被冻结渠道,无法进行操作 -->
    <div class="channel-mask" v-if="status == '1'"></div>

    <img class="bj" src="../assets/images2/home-bj.png" alt="" />
    <div class="content">
      <div class="card-box1">
        <div class="card-item1">
          <img class="icon" src="../assets/images2/owern-icon.png" alt="" />
          <div class="text-box">
            <div class="title">物流运输企业贷</div>
            <div class="text">最高可借<span>500万</span> 年利率<span>6%</span>起</div>
          </div>
          <div class="btn yellow-btn" @click="goApply(2)">
            评估额度
          </div>
        </div>
        <div class="card-item1">
          <img class="icon" src="../assets/images2/car-icon.png" alt="" />
          <div class="text-box">
            <div class="title">营运车辆车主贷</div>
            <div class="text">最高可借<span>30万</span> 年利率<span>8%</span>起</div>
          </div>
          <div class="btn red-btn" @click="goApply(1)">
            评估额度
          </div>
        </div>
      </div>
      <div class="card-box2">
        <div class="card-item2">
          <div class="title">物流运输企业贷</div>
          <div class="img-box1">
            <div class="icon-box">不用抵押担保</div>
            <div class="icon-box">企业名下有车</div>
            <div class="icon-box">最高可借500万</div>
          </div>
          <div class="center-img-box box1">
            <div class="text1">企业增信提额方案</div>
            <div class="text2">借款额度高于同类产品 <span>2</span> 倍</div>
          </div>
          <div class="footer">
            <div class="text">年利率（单利）区间：<span>6%-8%</span></div>
            <div class="btn" @click="goList(2)">立即申请</div>
          </div>
        </div>
        <div class="card-item2">
          <div class="title">营运车辆车主贷</div>
          <div class="img-box2">
            <div class="icon-box">不扣车 不装GPS</div>
            <div class="icon-box">名下有车就能申请</div>
            <div class="icon-box">最高可借30万</div>
          </div>
          <div class="center-img-box box2">
            <div class="text1">全线上流程快贷方案</div>
            <div class="text2">申请便捷、省时省力、到账快</div>
          </div>
          <div class="footer">
            <div class="text">年利率（单利）区间：<span>8%-10%</span></div>
            <div class="btn" @click="goList(1)">立即申请</div>
          </div>
        </div>
      </div>
    </div>
    <img class="bj1" src="../assets/images2/bottom-bj.png" alt="" />
    
    <!-- 渠道冻结弹窗 -->
    <van-dialog
      :show="showToast"
      title="提示"
      mask
      button-text="确定"
      :showCancelButton="false"
      @confirm="showToast = false"
      @close="showToast = false"
    >
      <div style="padding: 20px">
        该申请渠道已被冻结，无法使用本二维码进行申请贷款。
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { showToast } from "vant";
import { getchannelstatusByQrId } from "@/api/pages.js";
export default {
  name: "HomeView",
  props: {
    pageTitle: String,
    backgroundColor: String,
  },
  data() {
    return {
      qrId: "", //渠道id
      status: "", // 渠道状态 0正常 1冻结
      showToast: false, // 渠道冻结弹窗
    };
  },
  mounted() {
    
    window.scrollTo(0, 0); // 水平滚动为0，垂直滚动为0
    // 通过url参数获取渠道id
    // this.qrId = window.location.href.split("?")[1].split("id=")[1];
    // let url = window.location.href
    // var object = {};
    // if (url.indexOf("?") != -1) {
    //   //url中存在问号，也就说有参数。
    //   var str = url.split("?")[1]; //得到?后面的字符串
    //   var strs = str.split("&"); //将得到的参数分隔成数组
    //   for (var i = 0; i < strs.length; i++) {
    //     let index = strs[i].indexOf("=") +1;
    //     object[strs[i].split("=")[0]] = strs[i].substring(index); //得到{access_token:'123456',areaId:'www'}
    //   }
    // }
    // this.qrId =object.id
    // localStorage.setItem("qrId", object.id);
    // localStorage.setItem("token", object.token);
    // console.log(object)
    // console.log(object.id)
    this.getChannelStatus();
  },
  methods: {
    // 根据二维码id查询渠道状态
    getChannelStatus() {
      getchannelstatusByQrId(localStorage.getItem("qrId")).then((res) => {
        if (res.data.code === 200) {
          this.status = res.data.message;
          // 已被冻结渠道的二维码管理，无法操作弹窗提示
          if (this.status == "1") {
            this.showToast = true;
          }
        }
      });
    },
    goList(type){
      this.$point.enterFunc({
        tableName: "金融首页立即申请",
        parentPage: "金融",
        level: 1,
      });
      // localStorage.setItem("productType", type);
      this.$router.push({
          path: "/projectList",
          query: {
            qrId: localStorage.getItem("qrId"),
            type:type
          },
      });
    },
    goApply(type) {
      localStorage.setItem("productType", type);
      if (type == 1) {
        //运营车
        this.$router.push({
          path: "/pages/driver-form",
          query: {
            qrId: localStorage.getItem("qrId"),
          },
        });
      } else if (type == 2) {
        //企业
        this.$router.push({
          path: "/pages/enterprise-form",
          query: {
            qrId: localStorage.getItem("qrId"),
          },
        });
      }
      this.$point.enterFunc({
        tableName: "金融首页评估额度",
        parentPage: "金融",
        level: 1,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  height: 35.8rem;
  background-color: #F3252B;
  position: relative;
  .bj {
    position: absolute;
    top: 0;
    left: 0;
    width: 7.5rem;
  }
  .bj1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 7.5rem;
    z-index: 10;
  }
  .content {
    width: 100%;
    position: relative;
    top: 9.6rem;
    left: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0.24rem 0 0.6rem;
  }
  .card-box1{
    .card-item1{
      width: 6.93rem;
      height: 1.94rem;
      background: url('../assets/images2/home-card-bj1.png') no-repeat 100% 100%;
      background-size: 6.93rem 1.94rem;
      margin-bottom: 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.34rem;
      box-sizing: border-box;
      .icon{
        width: 0.88rem;
        height: 0.88rem;
      };
      .text-box{
        .title{
          color: #372509;
          font-size: 0.36rem;
          font-weight: 700;
        }
        .text{
          font-size: 0.24rem;
          margin-top: 0.08rem;
          color: #B87749;
          span{
            display: inline-block;
            color: #372509;
            font-weight: 700;
          }
        }
      }
      .btn{
        width: 1.91rem;
        height: 0.74rem;
        line-height: 0.82rem;
        text-align: center;
        font-weight: 500;
        font-size: 0.32rem;
        color: #FFFFFF;
      }
      .red-btn{
        background: url("../assets/images2/red-btn1.png") no-repeat 100% 100%;
        background-size: 1.91rem 0.74rem;
      }
      .yellow-btn{
        background: url("../assets/images2/yellow-btn1.png") no-repeat 100% 100%;
        background-size: 1.91rem 0.74rem;
      }
    }
  }
  .card-box2{
    margin-top: 0.14rem;
    .card-item2{
      width: 6.93rem;
      height: 10.25rem;
      background: url('../assets/images2/home-card-bj2.png') no-repeat 100% 100%;
      background-size: 6.93rem 10.25rem;
      margin-bottom: 0.4rem;
      padding: 0 0.24rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .title{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 4.87rem;
        height: 0.78rem;
        background: url('../assets/images2/card2-title-bj.png') no-repeat 100% 100%;
        background-size: 4.87rem 0.78rem;
        font-weight: bold;
        font-size: 0.4rem;
        color: #372509;
        line-height: 0.78rem;
        text-align: center;
      }
      .img-box1{
        width: 100%;
        position: absolute;
        top: 1.8rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        .icon-box{
          width: 1.72rem;
          height: 1.72rem;
          font-size: 0.24rem;
          color: #A45324;
          line-height: 2.4rem;
          text-align: center;
        }
        .icon-box:nth-of-type(1){
          background: url('../assets/images2/icon1.png') no-repeat 100% 100%;
          background-size: 1.72rem 1.72rem;
          position: relative;
          top: 0;
          left: 0.4rem;
        }
        .icon-box:nth-of-type(2){
          background: url('../assets/images2/icon2.png') no-repeat 100% 100%;
          background-size: 1.72rem 1.72rem;
          position: relative;
          top: -0.6rem;
          left: 0;
        }
        .icon-box:nth-of-type(3){
          background: url('../assets/images2/icon3.png') no-repeat 100% 100%;
          background-size: 1.72rem 1.72rem;
          position: relative;
          top: 0;
          right: 0.4rem;
        }
      }
      .img-box2{
        width: 100%;
        position: absolute;
        top: 1.8rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        .icon-box{
          // width: 1.1rem;
          height: 1.1rem;
          font-size: 0.24rem;
          color: #A45324;
          line-height: 2.6rem;
          text-align: center;
        }
        .icon-box:nth-of-type(1){
          background: url('../assets/images2/icon4.png') no-repeat 50% 100%;
          background-size: 1.1rem 1.1rem;
          position: relative;
          top: 0;
          left: 0.7rem;
        }
        .icon-box:nth-of-type(2){
          background: url('../assets/images2/icon5.png') no-repeat 50% 100%;
          background-size: 1.1rem 1.1rem;
          position: relative;
          top: -0.6rem;
          left: 0;
        }
        .icon-box:nth-of-type(3){
          background: url('../assets/images2/icon6.png') no-repeat 50% 100%;
          background-size: 1.1rem 1.1rem;
          position: relative;
          top: 0;
          right: 0.7rem;
        }
      }
      .center-img-box{
        position: relative;
        .text1{
          width: 100%;
          font-weight: 700;
          font-size: 0.36rem;
          color: #000000;
          text-align: center;
          position: absolute;
          bottom: 0.76rem;
          left: 50%;
          transform: translateX(-50%);
        }
        .text2{
          width: 100%;
          font-weight: 400;
          font-size: 0.28rem;
          color: #804427;
          text-align: center;
          position: absolute;
          bottom: 0.2rem;
          left: 50%;
          transform: translateX(-50%);
          span{
            color: #EF3E26;
            font-weight: bold;
          }
        }
      }
      .box1{
        width: 6.53rem;
        height: 4.61rem;
        margin-top: 3.2rem;
        background: url('../assets/images2/owern-img.png') no-repeat;
        background-size: 6.53rem 4.61rem;
      }
      .box2{
        width: 5.64rem;
        height: 4.87rem;
        margin-top: 2.94rem;
        background: url('../assets/images2/car-img.png') no-repeat;
        background-size: 5.64rem 4.87rem;
      }
      .footer{
        margin-top: 0.26rem;
        .text{
          font-weight: 500;
          font-size: 0.32rem;
          color: #C1865A;
          text-align: center;
          span{
            color: #BB1813;
            font-weight: 700;
          }
        }
        .btn{
          width: 5.64rem;
          height: 0.88rem;
          margin-top: 0.24rem;
          background: url('../assets/images2/card2-btn.png') no-repeat;
          background-size: 5.64rem 0.88rem;
          font-weight: 500;
          font-size: 0.36rem;
          color: #FFFFFF;
          line-height: 0.88rem;
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }
}
.channel-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
}
</style>
