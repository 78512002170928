import { enterTable,saveStatisticsTime } from "@/api/pages.js";
//进入页面
const enterFunc = (data) => {
    console.log(data,'埋点')
	enterTable(data).then(res => {
        if(res.data.code==200){
            saveStatisticsTime({id:res.data.message}).then(res => {
                if(res.data.code==200){
                }
                
            })
        }
		
	})
}
//离开页面
const leaveFunc = () => {
	saveStatisticsTime({id:localStorage.getItem("enterId")}).then(res => {
        if(res.data.code==200){
        }
		
	})
}

export default {
	enterFunc,
	leaveFunc,
}